<template>
  <base-layout>
    <ion-content class="has-header has-subheader">
      <div v-if="favorites">

        <IonSegment :value="activeTab"   scrollable style="justify-content: space-between" >
          <IonSegmentButton
              value="sessions"
              @click="changeTab('sessions')" style="min-width:130px"
          >
            <IonLabel>Sessions <span
                class="position-absolute top-0 m-3 end translate-middle badge rounded-pill bg-primary">
                {{ sessions.length }}
              </span></IonLabel>
          </IonSegmentButton>
          <IonSegmentButton
              value="presentations"
              @click="changeTab('presentations')" style="min-width:180px"
          >
            <IonLabel>Presentations<span
                class="position-absolute top-0 m-3 end translate-middle badge rounded-pill bg-primary">
                {{ presentations.length }}
              </span></IonLabel>
          </IonSegmentButton>
          <IonSegmentButton
              value="persons"
              @click="changeTab('persons')" style="min-width:130px"
          >
            <IonLabel>Persons<span
                class="position-absolute top-0 m-3 end translate-middle badge rounded-pill bg-primary">
                {{ persons.length }}
              </span></IonLabel>
          </IonSegmentButton>
          <IonSegmentButton
              value="exhibitors"
              @click="changeTab('exhibitors')" style="min-width:150px"
          >
            <IonLabel>Industry<span
                class="position-absolute top-0 m-3 end translate-middle badge rounded-pill bg-primary">
                {{ exhibitors.length }}
              </span></IonLabel>
          </IonSegmentButton>
        </IonSegment>
        <div v-if="activeTab =='sessions'">
          <session-list :sessions="sessions"></session-list>
        </div>
        <div v-if="activeTab =='presentations'">
          <presentation-list :presentations="presentations"></presentation-list>
        </div>
        <div v-if="activeTab =='persons'">
          <person-list :persons="persons"></person-list>
        </div>
        <div v-if="activeTab =='exhibitors'">
          <exhibitor-list :exhibitors="exhibitors"></exhibitor-list>
        </div>
      </div>
    </ion-content>
  </base-layout>
</template>
<script>
import {IonContent, IonSegment, IonSegmentButton, IonLabel} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions} from "vuex";
import SessionList from "@/components/SessionList";
import PersonList from "@/components/PersonList";
import PresentationList from "@/components/PresentationList";
import ExhibitorList from "@/components/ExhibitorList";

const config = require('@/config');

export default defineComponent({
  name: "Subpages",
  data() {
    return {
      favorites: [],
      sessions: [],
      presentations: [],
      persons: [],
      exhibitors: [],
      activeTab: "sessions",
    };
  },
  components: {
    IonContent,
    "session-list": SessionList,
    "person-list": PersonList,
    "presentation-list": PresentationList,
    "exhibitor-list": ExhibitorList,
    IonSegment,
    IonSegmentButton,
    IonLabel
  },
  methods: {
    ...mapActions("personalDB", ["connectPersonalDB", 'getItem', 'getItems', 'prepareFavoriteList']),
    changeTab(section) {
      this.activeTab = section;
    },
  },

  async created() {
    await this.connectPersonalDB();
    let confFavorites = await this.getItem('favorites_' + config.conference);
    console.log('CONFFAV',confFavorites)
    this.favorites = await this.prepareFavoriteList(confFavorites);
    console.log(this.favorites)
    this.sessions = this.favorites.sessions;
    this.presentations = this.favorites.presentations;
    this.persons = this.favorites.persons;
    this.exhibitors = this.favorites.exhibitors;
  },
  watch: {
    '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(route) {
        if (route.name == 'My Congress') {
          await this.connectPersonalDB();
          let confFavorites = await this.getItem('favorites_' + config.conference);
          this.favorites = await this.prepareFavoriteList(confFavorites);
          console.log(this.favorites)
          this.sessions = this.favorites.sessions;
          this.presentations = this.favorites.presentations;
          this.persons = this.favorites.persons;
          this.exhibitors = this.favorites.exhibitors;
        }
      }
    }
  }
});
</script>
<style lang="scss" scoped>
a {
  text-decoration: none
}
</style>